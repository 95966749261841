<template>
  <div style="background: #f5f5f5; color: #000" v-cloak v-if="getisok">
    <div class="newxin_a" v-if="mengdianisok">
      <div class="biaoti">
        <div class="sjname" @click="tomendian" v-if="chain">
          <img class="morens" v-if="shangjia.defaultGId == 1" src="../../assets/img/moren2.png" alt />
          <span style="vertical-align: middle">{{ shangjia.name }}</span>
          <van-icon style="vertical-align: middle; margin-top: 2px" name="arrow-down" />
        </div>
        <!--  -->
        <van-dropdown-menu class="sjname" v-else>
          <van-dropdown-item v-model="shangjia.name" :title="shangjia.name" ref="item">
            <div class="lis_sj">
              <van-cell :value="item.name" v-for="(item, inndx) in shangjialis" :key="inndx" @click="qiehuan(item)">
              </van-cell>
            </div>
            <div style="padding: 2px 16px" class="qqshez">
              <van-button plain type="danger" class="qushezhi" size="small" @click="toshezhi">去设置</van-button>
            </div>
          </van-dropdown-item>
        </van-dropdown-menu>
        <van-row class="ai2" @click="togengai">
          <div v-if="chexin" class="ischexin">
            <div class="cwode">我的爱车</div>
            <div class="bwoche">
              <div class="cpbj left">{{ chexin.carNo }}</div>
              <div class="left" v-if="chexin.carBrandLogo">
                <img class="logou_che" :src="chexin.carBrandLogo" alt />
              </div>
              <div class="left" v-if="chexin.carBrand" style="font-weight: bold; margin-left: 8px">
                {{ chexin.carBrand }}-{{ chexin.carModel }}
              </div>
            </div>
          </div>
          <span v-else class="woai">添加我的爱车</span>
          <img class="right gengai" v-if="chexin" src="../../assets/img/qiehuanche.png" alt />
          <img class="right gengai" v-else src="../../assets/img/tianjiaac.png" alt />
        </van-row>
      </div>

      <van-swipe class="my-swipe" :autoplay="5000" indicator-color="white" style="margin-top: 42px">
        <van-swipe-item v-for="(item, index) in lunbo" :key="index">
          <img class="user-img" :src="item.img" @click="totiaozhuan(item)" />
        </van-swipe-item>
      </van-swipe>
      <div class="user-links urs_lis user-hes" ref="slide" style="
          white-space: nowrap;
          overflow-x: scroll;
          background: #f9f9f9;
          padding: 10px 0px;
        ">
        <div span="6" class="hua_lis" v-for="(item, index) in imgList" :key="index" @click="changeRoute(item.url)" style="
            font-size: 14px;
            color: #333;
            font-weight: bold;
            float: none;
            display: inline-block;
            letter-spacing: 0;
          ">
          <van-icon :name="item.img" size="44" style="padding-bottom: 0%" />
          {{ item.title }}
        </div>
      </div>

      <div class="user-links user-hes" ref="slide" style="
          white-space: nowrap;
          background: #f9f9f9;
          padding: 10px 0px;
          overflow: hidden;
        ">
        <div v-for="(item, index) in imgList3" :key="index" @click="changeRouteBY(item.url, item.title)" style="
            font-size: 12px;
            color: #333;
            font-weight: bold;
            float: none;
            float: left;
            letter-spacing: 0;
            width: 20%;
          ">
          <!-- ********************************* -->
          <div v-if="index == 0" style="position: relative">
            <div class>
              <van-icon :name="item.img" size="34" style="padding-bottom: 0%" />
              {{ item.title }}
            </div>
            <div class="dingwei2">
              <wx-open-launch-weapp id="launch-btn" username="gh_efe48943f7d5" path="pages/vehicleList/vehicleList">
                <script type="text/wxtag-template">
                  <style>
                    .btdd{
                      overflow: hidden;
                      float: left;
                    }
                    .btn {font-size: 12px;color: rgb(51, 51, 51);font-weight: bold;}
                    .btn_img{
                      width: 34px;
                    height: 34px;
                    padding-bottom: 0%;
                    display: block;
                    }
                  </style>
                  <div class="btdd">
                   <img class="btn_img" :src="czimg" alt="">
                  <span class="btn">{{item.title}}</span>
                  </div>
                </script>
              </wx-open-launch-weapp>
            </div>
          </div>

          <!-- *********************************** -->
          <div v-else-if="index == 1 || index == 2 || index == 3">
            <van-icon :name="item.img" size="34" style="padding-bottom: 0%" />
            {{ item.title }}
          </div>
          <div v-else style="position: relative">
            <div class="dingwei">
              <van-icon :name="item.img" size="34" style="padding-bottom: 0%" />
              {{ item.title }}
            </div>
            <div class="dingwei2">
              <wx-open-launch-weapp id="launch-btn" username="gh_064786d1a083" path="yb_mingpian/pages/shop/index.html">
                <script type="text/wxtag-template">
                  <style>
                    .btdd{
                      overflow: hidden;
                      float: left;
                    }
                    .btn {font-size: 12px;color: rgb(51, 51, 51);font-weight: bold;}
                    .btn_img{
                      width: 34px;
                    height: 34px;
                    padding-bottom: 0%;
                    display: block;
                    }
                  </style>
                  <div class="btdd">
                   <img class="btn_img" :src="czimg" alt="">
                  <span class="btn">{{item.title}}</span>
                  </div>
                </script>
              </wx-open-launch-weapp>
            </div>
          </div>
        </div>
      </div>

      <van-row class="user-links user-bor">
        <!-- <div style="overflow: hidden; padding:0px 12px 12px 12px">
        <img class="miaosha left"  src="../../assets/img/miaosha.png" />
        <span class="zhe" @click="genduo">
          <span style="vertical-align: middle; margin-right: 5px;">更多</span>
          <van-icon style="display: inline;font-size: 12px;vertical-align: middle;" name="arrow" />
        </span>
        </div>-->
        <van-col class="msdiv" span="12" style="border-right: 1px solid #f5f5f5">
          <div @click="genduo">
            <img class="miaosha" src="../../assets/img/xsmiaosha.png" alt />
          </div>
          <div class="mis_bot">
            <div ref="menuItem" class="left" @click="to_details(mslis, 'ms')">
              <img class="mis_imgs" v-if="mslis" :src="mslis.coverImg1" alt />
            </div>
            <div ref="menuItem2" class="right" @click="to_details(mslis2, 'ms')">
              <img class="mis_imgs" v-if="mslis2" style="float: right" :src="mslis2.coverImg1" alt />
            </div>
          </div>
        </van-col>
        <van-col class="msdiv" span="12" @click.native="tolibao('list_seckill')">
          <!-- 半价保养 -->
          <div class="mis_bot" v-if="ttform.halfPrice">
            <img style="width: 100%" v-if="ttform.halfPrice.img" :src="ttform.halfPrice.img" alt />
          </div>
        </van-col>
        <van-col span="24" style="border-top: 1px solid #f5f5f5; padding: 0px 10px">
          <div @click="tolibao(ttform.guide.url)" v-if="ttform.guide">
            <img style="width: 100%" :src="ttform.guide.img" alt />
          </div>
        </van-col>
        <!-- <van-col
        span="6"
        v-for="(item, index) in imgList2"
        :key="index"
        class="qianggou"
        @click="to_details(item)"
        style="font-size:13px;color:#333;font-weight: bold"
      >
        <img class="miao_img" v-if="item.type == 1" src="../../assets/img/taocan.png" alt />
        <img class="miao_img" v-else :src="item.coverImg1" alt />
        <p class="pinming">{{item.partName}}</p>
        <span class="jin">￥{{item.activityPrice}}</span>
        <del class="del">￥{{item.sellPrice}}</del>
        </van-col>-->
      </van-row>

      <van-row class="user-links user-bor" style="
          padding: 0;
          border-radius: 0px;
          margin-top: 10px;
          margin-bottom: 10px;
        ">
        <van-col span="24" style="position: relative; height: 46px">
          <img class="zn_img" src="../../assets/img/toutiao.png" alt />
          <van-notice-bar class="gundong" background="#fff" color="#333" :scrollable="false">
            <van-swipe vertical class="notice-swipe" :autoplay="3000" :show-indicators="false">
              <van-swipe-item v-for="item in ttform.headlinesDOS" :key="item.id" @click.native="toweburl(item.url)">
                {{ item.img }}
              </van-swipe-item>
            </van-swipe>
          </van-notice-bar>
        </van-col>
      </van-row>
      <van-row class="user-links user-bor" style="position: relative">
        <!-- 天天平价 -->

        <van-col span="12" @click.native="totaocan(true)" style="border-right: 1px solid #f5f5f5; padding: 10px 0px 0px 0px">
          <img class="gits" src="../../assets/img/taocangit.png" alt />

        </van-col>
        <!-- 品牌集结 -->
        <van-col span="12" @click.native="totaocan(false)" style="padding: 10px 8px 0px 8px">
          <img class="gits" src="../../assets/img/taocangit2.png" alt />
        </van-col>
        <img src="../../assets/img/taocantop.png" class="taocantop" alt />
      </van-row>

      <van-row class="user-links user-bor" style="
          padding: 0;
          border-radius: 0px;
          margin-top: 10px;
          margin-bottom: 10px;
          background: #f5f5f5;
        ">
        <van-col span="24" style="position: relative; height: 46px">
          <img class="tb_img" src="../../assets/img/tbtj.png" alt />
        </van-col>
      </van-row>
      <div>
        <van-list v-model="loading" :finished="finished" :immediate-check="false" finished-text="没有更多了" @load="onLoads" :offset="10">
          <waterfall :col="col" :width="itemWidth" :gutterWidth="gutterWidth" :data="tjlist" @scroll="scroll">
            <template>
              <div class="cell-item" v-for="item in tjlist" :key="item.id" @click="to_details(item, 'jp')">
                <img class="miao_img" :lazy-src="item.coverImg1" :src="item && item.coverImg1" alt />
                <div class="item-body">
                  <div class="item-desc">
                    <span>{{ item.partName }}</span>
                  </div>
                  <div class="item-footer">
                    <div class="name" v-if="item.sellPrice > 0">
                      <span class="jin">
                        <span class="danwei">￥</span>
                        <span>{{ item.activityPrice }}</span>
                      </span>
                      <del class="del" v-if="item.sellPrice > item.activityPrice">￥{{ item.sellPrice }}</del>
                    </div>
                    <div class="name" v-else>
                      <span class="jin" style="font-size: 16px">面议</span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </waterfall>
        </van-list>
      </div>
    </div>
    <div v-else style="color: #000" class="newbeijin">
      <!-- //新的 -->

      <div class="newtou">
        <!-- <div class="sjname" @click="tomendian" v-if="chain">
          <img class="morens" v-if="shangjia.defaultGId == 1" src="../../assets/img/moren2.png" alt />
          <span style="vertical-align: middle">{{shangjia.name}}</span>
          <van-icon style="vertical-align: middle;margin-top: 2px;" name="arrow-down" />
        </div> -->
        <!-- v-else -->
        <!-- v-if="shangjia.id != 491" -->

        <van-dropdown-menu class="sjname" v-if="shangjia.id != 491">
          <van-dropdown-item v-model="shangjia.name" :title="shangjia.name" ref="item">
            <div class="lis_sj">
              <van-cell :value="item.name" v-for="(item, inndx) in shangjialis" :key="inndx" @click="qiehuan(item)">
              </van-cell>
            </div>
            <div style="padding: 2px 16px" class="qqshez">
              <van-button plain type="danger" class="qushezhi" size="small" @click="toshezhi">去设置</van-button>
            </div>
          </van-dropdown-item>
        </van-dropdown-menu>
        <div class="sjname" v-else></div>
        <van-row class="newchebei" @click="togengai" :class="shangjia.id == 491 ? 'newtop' : ''">
          <div v-if="chexin" class="ischexin">
            <div class="cwode">我的爱车</div>
            <div class="bwoche">
              <div class="cpbj left">{{ chexin.carNo }}</div>
              <div class="left" v-if="chexin.carBrandLogo">
                <img class="logou_che" :src="chexin.carBrandLogo" alt />
              </div>
              <div class="left" v-if="chexin.carBrand" style="font-weight: bold; margin-left: 8px">
                {{ chexin.carBrand }}-{{ chexin.carModel }}
              </div>
            </div>
          </div>
          <span v-else class="woai">添加我的爱车</span>
          <img class="right gengai" v-if="chexin" src="../../assets/newimg/qie.png" alt />
          <img class="right gengai" v-else src="../../assets/img/tianjiaac.png" alt />
        </van-row>
        <van-swipe class="new-swipe" :autoplay="5000" indicator-color="white" :class="shangjia.id == 491 ? 'newtopB' : ''">
          <van-swipe-item v-for="(item, index) in lunbo" :key="index">
            <img class="new-img" :src="item.img" @click="totiaozhuan(item)" />
          </van-swipe-item>
        </van-swipe>

        <div class="newtou_b" v-if="shangjia.id != 491">
          <div class="newtou_b_ac left" @click="tojiu(3)"></div>
          <!-- 768套餐 @click="tohuodong" -->
          <div class="newtou_b_b  left" @click="tohuodong">
            <img class="imgzuo2" src="../../assets/newimg/tpding2.png" alt />
          </div>
          <div class="newtou_b_a left" @click="tosupermarket">
            <img class="tejia" src="../../assets/newimg/chaoshi.png" alt />
            <div class="tejiatext">套餐超市</div>
          </div>
        </div>
        <div class="newicos">
          <div class="new-links user-hes" v-if="shangjia.id != 491">
            <div span="6" class="new_lis" v-for="(item, index) in newimgList" :key="index" @click="changeRoute(item.url)">
              <van-icon :name="item.img" size="31" style="padding-bottom: 0%" />
              {{ item.title }}
            </div>
          </div>
          <div class="new-links user-hes" v-else>
            <div span="6" class="new_lis" v-for="(item, index) in newimgList3" :key="index" @click="changeRoute(item.url)">
              <div v-if="index != 4">
                <van-icon :name="item.img" size="31" style="padding-bottom: 0%" />
                {{ item.title }}
              </div>
              <div v-else style="position: relative">
                <!-- dingwei -->
                <div class>
                  <van-icon :name="item.img" size="31" style="padding-bottom: 0%" />
                  {{ item.title }}
                </div>
                <div class="dingwei2">
                  <wx-open-launch-weapp id="launch-btn" username="gh_064786d1a083" path="yb_mingpian/pages/shop/index.html">
                    <script type="text/wxtag-template">
                      <style>
                        .btdd{
                          overflow: hidden;
                          float: left;
                        }
                        .btn {font-size: 12px;color: rgb(51, 51, 51);font-weight: bold;}
                        .btn_img{
                          width: 34px;
                        height: 34px;
                        padding-bottom: 0%;
                        display: block;
                        }
                      </style>
                      <div class="btdd">
                       <img class="btn_img" :src="czimg" alt="">
                      <span class="btn">{{item.title}}</span>
                      </div>
                    </script>
                  </wx-open-launch-weapp>
                </div>
              </div>
            </div>
          </div>
          <div class="new-links user-hes" style="margin-top: 10px" v-if="shangjia.id != 491">
            <div span="6" class="new_lis" v-for="(item, index) in newimgList2" :key="index" @click="changeRouteBY(item.url, item.title)">
              <!-- ---------dddddddddddddd---- -->
              <!-- <van-icon :name="item.img" size="31" style="padding-bottom: 0%;" />
              {{item.title}}-->

              <div v-if="index == 0" style="position: relative">
                <!-- dingwei -->
                <div class>
                  <van-icon :name="item.img" size="31" style="padding-bottom: 0%" />
                  {{ item.title }}
                </div>
                <div class="dingwei2">
                  <wx-open-launch-weapp id="launch-btn" username="gh_efe48943f7d5" path="pages/vehicleList/vehicleList">
                    <script type="text/wxtag-template">
                      <style>
                        .btdd{
                          overflow: hidden;
                          float: left;
                        }
                        .btn {font-size: 12px;color: rgb(51, 51, 51);font-weight: bold;}
                        .btn_img{
                          width: 34px;
                        height: 34px;
                        padding-bottom: 0%;
                        display: block;
                        }
                      </style>
                      <div class="btdd">
                       <img class="btn_img" :src="czimg" alt="">
                      <span class="btn">{{item.title}}</span>
                      </div>
                    </script>
                  </wx-open-launch-weapp>
                </div>
              </div>

              <!--  -->
              <div v-else-if="index == 1 || index == 2 || index == 3">
                <van-icon :name="item.img" size="31" style="padding-bottom: 0%" />
                {{ item.title }}
              </div>
              <div v-else style="position: relative">
                <!-- dingwei -->
                <div class>
                  <van-icon :name="item.img" size="31" style="padding-bottom: 0%" />
                  {{ item.title }}
                </div>
                <div class="dingwei2">
                  <wx-open-launch-weapp id="launch-btn" username="gh_064786d1a083" path="yb_mingpian/pages/shop/index.html">
                    <script type="text/wxtag-template">
                      <style>
                        .btdd{
                          overflow: hidden;
                          float: left;
                        }
                        .btn {font-size: 12px;color: rgb(51, 51, 51);font-weight: bold;}
                        .btn_img{
                          width: 34px;
                        height: 34px;
                        padding-bottom: 0%;
                        display: block;
                        }
                      </style>
                      <div class="btdd">
                       <img class="btn_img" :src="czimg" alt="">
                      <span class="btn">{{item.title}}</span>
                      </div>
                    </script>
                  </wx-open-launch-weapp>
                </div>
              </div>

              <!-- ------------------- -->
            </div>
          </div>
        </div>
      </div>
      <div class="newgundong">
        <div class="newgundong_a left">今日头条</div>
        <van-notice-bar class="newgundong_b" background="#fff" color="#666" :scrollable="false">
          <van-swipe vertical class="notice-swipe" :autoplay="3000" :show-indicators="false">
            <van-swipe-item v-for="item in ttform.headlinesDOS" :key="item.id" @click="toweburl(item.url)">{{ item.img
            }}</van-swipe-item>
          </van-swipe>
        </van-notice-bar>
      </div>
      <!-- <div class="newchaozhikaiye" @click="tohuodong" v-if="shangjia.id != 491">
        <img class="newkaiyeimg" src="../../assets/newimg/kaiye.png" alt />
      </div> -->
      <div class="newchaozhikaiye" v-if="shangjia.id != 491">
        <img class="newjiuimg left" @click="tojiu(1)" src="../../assets/newimg/jiu1.jpg" alt />
        <img class="newjiuimg right" @click="tojiu(2)" src="../../assets/newimg/jiu2.jpg" alt />
      </div>
      <!-- <div class="newchaozhi" @click="tochongzi">
        <img class="newczimg" src="../../assets/newimg/czimg.png" alt />
        <div class="newczimg2">超值套餐</div>
        <div class="newtext">
          <div class="newtext_a">
            <span>洗车套餐低至</span>
            <span style="color: #ff1f21;">¥</span>
            <span class="newjin">49.8</span>
          </div>
          <div class="newtext_b">
            <span class="newxl">每人限量一份</span>
            <van-icon class="newicom" name="arrow" />
          </div>
        </div>
      </div>-->
      <div class="newjsxiche" @click="tozidongxicar" v-if="shangjia.id != 491"></div>
      <div class="newmiaosha">
        <div class="newms_a">
          <img class="czimg left" src="../../assets/newimg/czms.png" alt />
          <div class="right czname" @click="genduo">
            <span style="vertical-align: middle">更多好货限时疯抢</span>
            <van-icon name="arrow" style="vertical-align: middle" />
          </div>
        </div>

        <div class="newms_lis">
          <div ref="menuItem" class="left newms_lis_a" @click="to_details(mslis, 'ms')">
            <div class="new_neirong">
              <img class="hedimg" v-if="mslis" :src="mslis.coverImg1" alt />
              <div class="new_jiner" v-if="mslis">
                <span class="new_jiner_a">
                  <span class="suo">¥</span>
                  <span>{{ mslis.sellPrice ? mslis.sellPrice.toFixed(0) : '0' }}</span>
                  <span class="suo">{{ mslis.sellPrice ? formatPrice(mslis.sellPrice) : '.00' }}</span>
                </span>
                <span class="new_jiner_b">
                  <span>¥{{ mslis.activityPrice ? mslis.activityPrice.toFixed(2) : '0.00' }}</span>
                  <img src="../../assets/img/xvip.png" alt="">
                </span>
              </div>
            </div>
          </div>
          <div ref="menuItem2" class="right newms_lis_b" @click="to_details(mslis2, 'ms')">
            <div class="new_neirong">
              <img class="hedimg" v-if="mslis2.coverImg1" :src="mslis2.coverImg1" alt />
              <div class="new_jiner" v-if="mslis2">
                <span class="new_jiner_a" v-if="mslis2.activityPrice">
                  <span class="suo">¥</span>
                  <span>{{ mslis2.sellPrice ? mslis2.sellPrice.toFixed(0) : '0' }}</span>
                  <span class="suo">{{ mslis2.sellPrice ? formatPrice(mslis2.sellPrice) : '.00' }}</span>
                </span>
                <span class="new_jiner_b" v-if="mslis2.sellPrice"> <span>¥{{ mslis2.activityPrice ?
                    mslis2.activityPrice.toFixed(2) : '0.00'
                }}</span>
                  <img src="../../assets/img/xvip.png" alt=""></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="newqtbaoyan">
        <van-list v-model="loading" :finished="finished" :immediate-check="false" finished-text="没有更多了" @load="onLoadsT" :offset="10">
          <div class="qtbaoyan_a" v-for="item in tjlist" :key="item.id" @click="to_details(item, 'jp')">
            <div class="newjptext">{{ item.partName }}</div>
            <img class="new_jinimg" :lazy-src="item.coverImg1" :src="item && item.coverImg1" alt />
            <div class="new-body">
              <div class="flx" v-if="item.sellPrice > 0">
                <span class="jin">
                  <span class="danwei">￥</span>
                  <span>{{ item.sellPrice ? item.sellPrice.toFixed(0) : '0' }}</span>
                  <span class="danwei">{{ item.sellPrice ? formatPrice(item.sellPrice) : '.00' }}</span>
                </span>
                <span class="del" v-if="item.sellPrice > item.activityPrice">
                  <span>￥{{ item.activityPrice ? item.activityPrice.toFixed(2) : '0.00' }}</span>
                  <img src="../../assets/img/xvip.png" alt="">
                </span>

                <span class="dian" @click.stop="toerweima(item)" v-if="users.usedCar == 3">···</span>
              </div>
              <div class="name" v-else>
                <span class="jin" style="font-size: 16px">面议</span>
              </div>
            </div>
          </div>
        </van-list>
        <!-- <div class="qtbaoyan_a"></div>
        <div class="qtbaoyan_b"></div>-->
        <div class="xicarposx" @click="tonewcar" v-if="shangjia.id != 491">
          <img src="../../assets/newimg/yucar.png" alt />
          <span class="carnube">{{ carnuber }}</span>
          <!-- <img src="../../assets/newimg/xicar.png" alt /> -->
        </div>
      </div>
    </div>
    <!-- <van-overlay :show="quanisok" @click="quanisok = false">
      <div class="wrapper" @click.stop>
        <div class="block">
          <img @click.stop="tiaozhuan" class="qianggpu" src="../../assets/img/qiang.png" alt />
          <van-icon @click.stop="quanisok = false" class="guanbi" name="close" />
        </div>
      </div>
    </van-overlay>-->
    <div class="zhuantai" v-if="show">
      <img class="cheche" src="../../assets/img/cheche.png" alt />
      <span class="chep" @click="showPicker = true">{{ clist.carNo }}</span>
      <img class="xiaxia" src="../../assets/img/cxia.png" alt />
      <img class="xyaoshiimg" v-if="clist.carKey" @click.stop="toyaoshi(clist.carKey)" src="../../assets/newimg/yaosi.png" alt />
      <span class="shigong" @click.stop="toschedule">
        <span v-if="clist.status == 1">报价中</span>
        <span v-if="clist.status == 2">施工中</span>
        <span v-if="clist.status == 3">施工完成</span>
        <span v-if="clist.status == 4">质检中</span>
        <span v-if="clist.status == 5 || clist.status == 6">质检完成</span>
        <span v-if="clist.status == 7" style="color: #07c160">已完成</span>
      </span>
      <img class="xiaxiad" src="../../assets/img/cdin.png" alt />
      <!-- v-if="clist.url" -->
      <img class="zhiboimg" v-if="clist.url" @click="tozhibo" src="../../assets/img/zhibo.png" alt />
      <span class="baojia" style="color: red" v-if="clist.status == 7" @click="tojiesuan">结算</span>
      <span class="baojia" v-else>未报价</span>
      <span class="moni" @click="toxianqin" v-if="clist.status == 6 || clist.status == 7">￥{{ clist.amountActual
      }}</span>
    </div>
    <div style="height: 55px"></div>
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker show-toolbar :columns="ddlist" value-key="billNumber" title="工单号" @cancel="showPicker = false" @confirm="onConfirm" />
    </van-popup>
    <van-popup class="tichengsw" v-model="tichenshow">
      <div class="ticname">{{ tcform.partName }}</div>
      <div class="tcje">
        提成金额：
        <span style="color: #07c160">￥{{ tcform.tokerPrice }}</span>
      </div>
      <van-button plain hairline class="tcboten" size="small" v-preventReClick @click="totanewm" type="primary">收款码
      </van-button>
    </van-popup>
    <van-popup class="ewmlis" v-model="ewmchenshow" @close="jianting">
      <div class="mian">
        <div class="shoukuan">
          <img class="daizi" src="../../assets/img/daizi.png" alt />
          <span class="shou">二维码收款</span>
        </div>
        <div class="ma">
          <div class="haoyou">{{ tcform.partName }}</div>
          <div class="jiner">
            <span style="font-size: 16px">￥</span>
            <span>{{ tcform.activityPrice }}</span>
          </div>
        </div>
        <div class="qy_code_wrapper">
          <vue-qr :text="downloadData.url" :margin="5" colorDark="#000" colorLight="#fff" :dotScale="1" :logoSrc="downloadData.icon" :logoScale="0.2" :size="200"></vue-qr>
        </div>
      </div>
    </van-popup>
    <van-popup class="yaosierweim" v-model="yaoshishow" closeable>
      <div>取钥匙凭证码</div>
      <div class="qy_code_wrapper">
        <vue-qr :text="carKey" :margin="5" colorDark="#000" colorLight="#fff" :dotScale="1" :logoSrc="downloadData.icon" :logoScale="0.2" :size="200"></vue-qr>
      </div>
    </van-popup>
    <van-popup class="newcartan" v-model="newcartan">
      <img class="newcaiimg" src="../../assets/newimg/newcaiimg.png" alt />
      <img class="newcaiimg" @click="tosupermarket" src="../../assets/newimg/newcaiimg2.png" alt />
      <img class="newcaiimg" @click="changeRoute('washcar')" src="../../assets/newimg/newcaiimg1.png" alt />

      <img class="newcha" @click.stop="newcartan = false" src="../../assets/newimg/chacha.png" alt />
    </van-popup>
    <tabbar :active="0" v-if="tabshow"></tabbar>
  </div>
</template>

<script>
import tabbar from "../../components/tabbar.vue";
import preventReClick from "../../assets/preventReClick";
import userModel from "../../api/user";
import orderModel from "../../api/order";
import utils from "../../assets/utils";
import {
  sellManqrCode,
  statusquery,
  checkwash,
  washcount,
} from "../../api/check.js";
import vueQr from "vue-qr";
// import wx from "weixin-js-sdk";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  // mixins: [preventBack], //注入
  components: {
    tabbar,
    vueQr,
  },
  data() {
    return {
      userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},
      chexin: JSON.parse(sessionStorage.getItem("chexin")),
      shangjias: JSON.parse(sessionStorage.getItem("shangjia")),
      active: 0,
      tichenshow: false, //提成弹窗
      usedCar: null, //0: 车主客户 1:销售商 2:质检商。3销售
      ewmchenshow: false, //二维码弹窗
      tcform: {}, //提成数据
      timers: null, //提成二维码定时器
      downloadData: {},
      newcartan: false,
      carKey: null, //钥匙二维码
      carnuber: 0,
      getisok: false,
      yaoshishow: false,
      tabshow: true,
      value1: 0,
      gid: "",
      clist: {},
      users: {},
      ddlist: [],
      quanisok: false,
      showPicker: false,
      chain: true,
      show: false,
      scisok: true,
      lunbo: [],
      shangjia: {},
      shangjialis: [],
      imgList2: [],
      imgListpb: [],
      newimgList: [
        {
          title: "换轮胎",
          img: require("../../assets/newimg/fuwu1.png"),
          url: "list",
        },
        {
          title: "做保养",
          img: require("../../assets/newimg/fuwu2.png"),
          url: "maintain",
        },
        {
          title: "汽车美容",
          img: require("../../assets/newimg/fuwu3.png"),
          url: "washcar",
        },
        {
          title: "喷漆",
          img: require("../../assets/newimg/fuwu4.png"),
          url: "spray_lacquer",
        },
        {
          title: "精品",
          img: require("../../assets/newimg/fuwu5.png"),
          url: "car_tasted",
        },
      ],
      newimgList2: [
        {
          title: "精品二手车",
          img: require("../../assets/newimg/nerer.png"),
          url: "maintain",
        },
        {
          title: "小保养",
          img: require("../../assets/newimg/lis2.png"),
          url: "maintain",
        },
        {
          title: "年检年审",
          img: require("../../assets/newimg/lis3.png"),
          url: "inspect_car",
        },
        {
          title: "二手车质保",
          img: require("../../assets/newimg/lis4.png"),
          url: "Userowner",
          // guarantee
        },
        {
          title: "车主生活",
          img: require("../../assets/newimg/lis5.png"),
          url: "",
        },
      ],
      newimgList3: [
        {
          title: "汽车美容",
          img: require("../../assets/newimg/fuwu3.png"),
          url: "washcar",
        },
        {
          title: "做保养",
          img: require("../../assets/newimg/fuwu2.png"),
          url: "maintain",
        },
        {
          title: "喷漆",
          img: require("../../assets/newimg/fuwu4.png"),
          url: "spray_lacquer",
        },
        {
          title: "年检年审",
          img: require("../../assets/newimg/lis3.png"),
          url: "inspect_car",
        },
        {
          title: "车主生活",
          img: require("../../assets/newimg/lis5.png"),
          url: "",
        },
      ],
      imgList: [
        {
          title: "换轮胎",
          img: require("../../assets/img/fuwu1.png"),
          url: "list",
        },
        {
          title: "做保养",
          img: require("../../assets/img/fuwu2.png"),
          url: "maintain",
        },
        {
          title: "洗车美容",
          img: require("../../assets/img/fuwu3.png"),
          url: "washcar",
        },
        {
          title: "喷漆",
          img: require("../../assets/img/fuwu4.png"),
          url: "spray_lacquer",
        },
        {
          title: "精致车品",
          img: require("../../assets/img/fuwu5.png"),
          url: "car_tasted",
        },
      ],
      imgList3: [
        {
          title: "精品二手车",
          img: require("../../assets/newimg/nerer.png"),
          url: "maintain",
        },
        {
          title: "小保养",
          img: require("../../assets/img/lis2.png"),
          url: "maintain",
        },
        {
          title: "年检年审",
          img: require("../../assets/img/lis3.png"),
          url: "inspect_car",
        },
        {
          title: "二手车质保",
          img: require("../../assets/img/lis4.png"),
          url: "Userowner",
          // guarantee
        },
        {
          title: "车主生活",
          img: require("../../assets/img/lis5.png"),
          url: "",
        },
      ],
      col: 2,
      czimg:
        "http://vehicle-repairs.oss-cn-hangzhou.aliyuncs.com/wx/3495/1622624637391lis5.png?Expires=1937984637&OSSAccessKeyId=LTAI4FhBMt2eaMxuMMSCECEm&Signature=XWwcu%2FAFzOtS%2FwyDQYHQOaDnPlQ%3D",
      tjlist: [],
      ttform: {},
      mslis: {}, // 图片路径
      msid: 0, // 默认id 从零开始
      mslis2: {}, // 图片路径
      msid2: 0, // 默认id 从零开始
      temp: [],
      ranm: [],
      clearTimeSet: null,
      mengdianisok: true,
      num: 1,
      page: 0,
      size: 6,
      loading: false,
      total: 0,
      finished: false,
    };
  },
  computed: {
    itemWidth() {
      console.log(document.documentElement.clientWidth);
      return 133 * 1.3 * (document.documentElement.clientWidth / 375);
    },

    gutterWidth() {
      return 10 * 0.5 * (document.documentElement.clientWidth / 375) + 2;
    },
  },
  // watch: {
  //   $route(to, from) {
  //     this.$router.go(0);
  //   }
  // },

  methods: {
    //监听弹窗关闭
    jianting() {
      clearInterval(this.timers);
      console.log("监听关闭定时器成功！");
    },
    //弹出二维码
    totanewm() {
      var _this = this;
      const data = {
        gid: this.shangjia.id,
        id: this.tcform.id,
      };
      sellManqrCode(data)
        .then((e) => {
          this.downloadData = {
            url: e.data.code_url,
            icon: require("../../assets/img/1.png"), //二维码中间的图片,可以不设置
          };
          this.ewmchenshow = true;
          _this.getOrderstate(e.data.out_trade_no);
        })
        .catch(() => loading.clear());
    },
    getOrderstate(out_trade_no) {
      //判断支付是否成功，监听微信支付是否成功
      console.log(out_trade_no);
      let _this = this;
      let num = 0;
      _this.timers = setInterval(() => {
        num++;
        statusquery({ out_trade_no: out_trade_no }).then((res) => {
          console.log(res);
          console.log(res.code);
          if (res.code == 200) {
            if (res.data.trade_state == "SUCCESS") {
              clearInterval(_this.timers);
              // this.$message.success("支付成功");
              _this.ewmchenshow = false;
              _this.tichenshow = false;
              _this.$toast({
                message: "支付成功",
                type: "success",
                duration: 3000,
              });
            }
          }
        });
        if (num == 90) {
          (this.ispay = false),
            //别忘了清除定时器
            clearInterval(this.timers);
        }
      }, 2000);
    },
    //瀑布流
    loadmore(index) {
      console.log(index);
      this.tjlist = this.tjlist.concat(this.tjlist);
    },
    scroll(e) {
      // console.log(e);
    },
    //
    togengai() {
      if (this.chexin) {
        this.$router.push({
          name: "model",
        });
      } else {
        window.location.href = "/authentication?auth=" + 0;
      }
    },
    tochongzi() {
      window.location.href =
        "https://marketing.car-posthouse.cn/indexmian?aid=62&uid=0&ssid=0&gid=484";
    },
    //跳转预约洗车
    tonewcar() {
      checkwash().then((res) => {
        if (res.code == 200) {
          console.log(res.data);
          if (res.data == true) {
            this.$router.push({
              path: "order_yuyue",
              query: {
                carisok: true,
                appointmentId: 458,
              },
            });
          } else {
            this.newcartan = true;
          }
        } else {
          this.$toast(res.data.message);
        }
      });
    },
    //跳转维修详情
    toxianqin() {
      this.$router.push({
        path: "consume_details",
        query: {
          id: this.clist.oid,
          title: "维修报价",
        },
      });
    },
    //跳转套餐商城
    tosupermarket() {
      window.location.href = "/supermarket?gid=" + this.shangjia.id;
    },
    //跳转直播
    tozhibo() {
      window.location.href =
        "https://open.ys7.com/view/h5/2989ea0378214e43bf858ed8f68df4d7";
    },
    //跳转外部链接
    toweburl(url) {
      if (url) {
        window.location.href = url;
      }
    },

    //跳转开业庆典
    tohuodong() {
      window.location.href = "/start_business?gid=" + this.shangjia.id;
      // this.$router.push({
      //   path: "start_business",
      //   query: {}
      // });
    },
    //跳转88元礼包
    tolibao(url) {
      if (url == "" || url == null) {
        return;
      } else {
        window.location.href = url;
        // this.$router.push({
        //   path: url
        // });
      }
    },
    //去设置
    toshezhi() {
      this.$router.push({
        path: "common",
      });
    },
    toyaoshi(carKey) {
      this.carKey = carKey;
      this.yaoshishow = true;
    },
    //跳转维修进度
    toschedule() {
      this.$router.push({
        path: "schedule",
        query: {
          id: this.clist.oid,
          carNo: this.clist.carNo,
          status: this.clist.status,
        },
      });
    },
    genduo() {
      this.$router.push({
        path: "list_miao",
      });
    },
    onLoad() {
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中...",
      });
      userModel
        .getGarageInfo({ gid: sessionStorage.getItem("gid") })
        .then((e) => {
          loading.clear();
          // this.shangjia = e.data;
          this.chain = e.data.chain;
          sessionStorage.setItem("appid", e.data.idd);
          sessionStorage.setItem("cappid", e.data.idd);
          sessionStorage.setItem("shangjia", JSON.stringify(this.shangjia));
          localStorage.setItem("beforeUrl", this.$route.path);
          console.log(utils.getUrlKey("gid"));
          if (e.data.id == 484 || e.data.id == 208 || e.data.id == 491) {
            this.mengdianisok = false;
          } else {
            this.mengdianisok = true;
          }

          if (
            utils.getUrlKey("gid") != null &&
            utils.getUrlKey("gid") != localStorage.getItem("Sgid")
          ) {
            const redirect_uri = encodeURIComponent(
              "https://shopping.car-posthouse.cn/auths"
            );
            localStorage.setItem("gid", utils.getUrlKey("gid"));
            localStorage.setItem("Sgid", utils.getUrlKey("gid"));
            window.location.href = `https://marketing.car-posthouse.cn/get-weixin-code.html?appid=${e.data.idd}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;

            return;
          }
          if (localStorage.getItem("Authorization") == null) {
            const redirect_uri = encodeURIComponent(
              "https://shopping.car-posthouse.cn/auths"
            );

            window.location.href = `https://marketing.car-posthouse.cn/get-weixin-code.html?appid=${e.data.idd}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
            return;
          }
          if (localStorage.getItem("userInfo") == "undefined") {
            const redirect_uri = encodeURIComponent(
              "https://shopping.car-posthouse.cn/auths"
            );

            window.location.href = `https://marketing.car-posthouse.cn/get-weixin-code.html?appid=${e.data.idd}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
            return;
          }
          if (
            this.userInfo.id == "" ||
            this.userInfo.id == null ||
            this.userInfo.id == "undefined" ||
            this.userInfo.id == undefined ||
            this.userInfo.id == "null"
          ) {
            const redirect_uri = encodeURIComponent(
              "https://shopping.car-posthouse.cn/auths"
            );

            window.location.href = `https://marketing.car-posthouse.cn/get-weixin-code.html?appid=${e.data.idd}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
            return;
          }

          setTimeout((res) => {
            this.$router.go(0);
          }, 100);

          // --------------------这里-------------------
          // if (JSON.parse(sessionStorage.getItem("chexin"))) {
          //   this.jianchache();
          // }
          // 测试
          // const redirect_uri = encodeURIComponent(
          //   "http://shoppingtest.car-posthouse.cn/auths"
          // );
          // window.location.href = `http://marketing.car-posthouse.cn/get-weixin-code.html?appid=${e.data.idd}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
        })
        .catch(() => loading.clear());
    },
    tozidongxicar() {
      this.$router.push({
        path: "Automatic_Carimg",
      });
    },
    tojiu(type) {
      this.$router.push({
        path: "jiuimg",
        query: {
          type: type,
        },
      });
    },
    //门店list
    tomendian() {
      this.$router.push({
        path: "Store_list",
        query: {
          isok: false,
          title: "点击更换门店",
        },
      });
    },
    //弹出收款二维码
    toerweima(item) {
      this.tcform = item;
      this.tichenshow = true;
      console.log(item);
    },
    //轮播图列表
    lunbolist() {
      var data = {
        gid: sessionStorage.getItem("gid"),
      };

      userModel
        .infolunbo(data)
        .then((e) => {
          // loading.clear();
          this.lunbo = e.data;
        })
        .catch(() => loading.clear());
    },
    //领取优惠券
    // linquan() {
    //   if (this.userInfo.customerId) {
    //     var data = {
    //       gid: sessionStorage.getItem("gid"),
    //       customerId: this.userInfo.customerId
    //     };
    //     userModel
    //       .limit(data)
    //       .then(e => {
    //         this.quanisok = e.data;
    //         sessionStorage.setItem("quan", e.data);
    //       })
    //       .catch(() => loading.clear());
    //   }
    // },
    hqcstID() {
      const data = {
        gid: this.shangjia.id,
        uid: this.userInfo.id,
        // uid: 1661
      };
      orderModel
        .count(data)
        .then((e) => {
          this.userInfo.customerId = e.data.customerId;
          localStorage.setItem("userInfo", JSON.stringify(this.userInfo));
        })
        .catch(() => loading.clear());
    },
    splist() {
      userModel.headline({ gid: sessionStorage.getItem("gid") }).then((e) => {
        this.ttform = e.data;
      });
    },
    onLoads() {
      console.log("asdsad");
      this.page++;
      this.tuijianlist();
    },
    onLoadsT() {
      console.log("asdsad");
      this.page++;
      this.tuijianlist();
    },

    //推荐列表
    tuijianlist() {
      var data = {
        gid: sessionStorage.getItem("gid"),
        type: 2,
        page: this.page,
        size: this.size,
      };
      userModel.miaoshalist(data).then((e) => {
        console.log("获取精品的推荐");
        let rows = e.data;
        this.loading = false;
        this.total = e.pageInfo.total;
        console.log(this.total);
        if (rows == null || rows.length === 0) {
          // 加载结束
          this.finished = true;
          return;
        }
        // 将新数据与老数据进行合并
        this.tjlist = this.tjlist.concat(rows);
        // this.loadmore()
        //如果列表数据条数>=总条数，不再触发滚动加载
        if (this.tjlist.length >= this.total) {
          this.finished = true;
        }
        console.log(this.loading);
        // this.tjlist = e.data;
      });
    },
    miaoshalist() {
      clearInterval(this.clearTimeSet);
      this.mslis = [];
      this.mslis2 = [];
      var data = {
        gid: sessionStorage.getItem("gid"),
        page: 0,
        size: 20,
        type: 0,
      };

      userModel
        .miaoshalist(data)
        .then((e) => {
          // loading.clear();
          let temp = [];
          let ranm = [];
          this.imgList2 = e.data;

          if (e.data.length > 2) {
            e.data.forEach((item, index) => {
              if (index % 2) {
                temp.push(item);
              } else {
                ranm.push(item);
              }
            });
            this.temp = temp;
            this.ranm = ranm;
            this.mslis = temp[0];
            this.mslis2 = ranm[0];
            this.$nextTick(() => {
              this.clearTimeSet = setInterval(() => {
                var _this = this;
                var name = this.num % 2 == 0 ? "偶数" : "奇数";
                // console.log(name);
                if (name == "奇数") {
                  _this.$refs.menuItem.style.opacity = "0";
                  if (this.msid >= this.imgList2.length) {
                    this.msid = 1;
                  } else {
                    this.msid += 1;
                  }
                  this.mslis = this.imgList2[this.imgList2.length - this.msid];
                  setTimeout(() => {
                    _this.$refs.menuItem.style.opacity = "1";
                    _this.$refs.menuItem.style.transition = "all .5s";
                  }, 200);
                } else {
                  _this.$refs.menuItem2.style.opacity = "0";
                  // 如果当前图片是最后一张就把id清零 从第一张开始
                  if (this.msid >= this.imgList2.length) {
                    this.msid = 1;
                  } else {
                    this.msid += 1;
                  }

                  this.mslis2 = this.imgList2[this.imgList2.length - this.msid];
                  setTimeout(() => {
                    _this.$refs.menuItem2.style.opacity = "1";
                    _this.$refs.menuItem2.style.transition = "all .5s";
                  }, 200);
                }

                this.num += 1;
              }, 5000);
              // this.ludong();
              // console.log(this.mslis);
            });
          } else if (e.data.length == 2) {
            this.mslis = e.data[0];
            this.mslis2 = e.data[1];
          } else {
            this.mslis = e.data[0];
          }
        })
        .catch(() => loading.clear());
    },

    changeRoute(url) {
      // console.log('dsad')
      this.$router.push({
        path: url,
        query: {
          Aswitch: 1,
        },
      });

      // this.$router.push({
      //   name: url
      // });
    },
    formatPrice(value) {
      // 截取当前数据到小数点后两位

      let realVal = parseFloat(value).toFixed(2).slice(-3);

      return realVal;
    },
    changeRouteBY(url, name) {
      console.log(this.users);
      if (name == "二手车质保") {
        if (this.users.usedCar == 0) {
          this.$router.push({
            path: "Userowner",
          });
        } else if (this.users.usedCar == 1) {
          this.$router.push({
            path: "guarantee",
            query: {
              cid: this.users.cid,
              sellCompany: this.users.sellCompany,
              usedCar: this.users.usedCar,
            },
          });
        } else {
          this.$router.push({
            path: "guarantee",
            query: {
              usedCar: this.users.usedCar,
            },
          });
        }
      } else {
        this.$router.push({
          path: url,
          query: {
            Aswitch: 1,
            name: name,
          },
        });
      }
      // console.log('dsad')
    },
    totiaozhuan(item) {
      if (item.url == "" || item.url == null) {
        return;
      } else {
        location.href = item.url;
        // this.$router.push({
        //   path: item.url,
        //   query: {
        //     gid: this.shangjia.id
        //   }
        // });
      }
    },
    //跳转套餐优惠
    totaocan(item) {
      // this.$router.push({
      //   path: "gift_newest",
      //   query: {
      //     isok: item,
      //   },
      // });
    },
    tiaozhuan() {
      sessionStorage.setItem("to", "true");
      this.$router.push({
        path: "youhui_linqu",
        // query: {
        //   Aswitch: 1
        // }
      });
    },
    to_details(item, name) {
      if (item.type == 0 || item.type == 2) {
        this.$router.push({
          path: "list_miao_details",
          query: {
            id: item.id,
            Aswitch: 2,
            cname: name,
            gid: sessionStorage.getItem("gid"),
          },
        });
      } else if (item.type == 1) {
        this.$router.push({
          path: "member_details",
          query: {
            id: item.id,
            type: 4,
          },
        });
        item.name = item.partName;
        item.type = 4;
        sessionStorage.setItem("huiyuan", JSON.stringify(item));
      }
    },
    getConfig() {
      let that = this;
      userModel
        .config({
          url: location.href.split("#")[0],
          gid: sessionStorage.getItem("gid"),
        })
        .then((res) => {
          if (res.code == 200) {
            console.log(res);
            wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: res.data.appid, // 必填，公众号的唯一标识
              timestamp: res.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.data.noncestr, // 必填，生成签名的随机串
              signature: res.data.signature, // 必填，签名
              jsApiList: [
                "getLocation",
                "updateAppMessageShareData",
                "updateTimelineShareData",
                "onMenuShareAppMessage",
              ],
              openTagList: ["wx-open-launch-weapp"], // 微信开放标签 小程序跳转按钮：<wx-open-launch-weapp>
            });
            wx.ready(function () {
              wx.hideMenuItems({
                menuList: ["menuItem:copyUrl"], // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮
              });
            });
            wx.ready(() => {
              wx.onMenuShareAppMessage({
                title: "驿车驹",
                desc: "邀请您使用驿车驹在线服务！",
                link:
                  "https://shopping.car-posthouse.cn/user?gid=" +
                  sessionStorage.getItem("Agid"),
                imgUrl:
                  "https://shopping.car-posthouse.cn/assets/img/fuwu1.2584c349.png",
                success: function () {},
              });
            });
            wx.ready(() => {
              wx.getLocation({
                type: "wgs84", // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
                success: function (res) {
                  console.log(res);
                  var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
                  var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
                  localStorage.setItem("JD", res.longitude);
                  localStorage.setItem("WD", res.latitude);
                  var speed = res.speed; // 速度，以米/每秒计
                  var accuracy = res.accuracy; // 位置精度
                },
              });
            });
          } else {
            this.$toast(res.data.msg);
          }
        });
    },
    jianchache() {
      const data = {
        gid: this.shangjia.id,
        carNo: this.chexin.carNo,
        // carNo: '粤cddddd'
      };
      userModel
        .unfinishList(data)
        .then((e) => {
          console.log(e.data.length);
          if (e.data.length != 0) {
            this.show = true;
            this.ddlist = e.data;
            this.clist = e.data[0];
          } else {
            this.ddlist = [];
            this.clist = {};
            this.show = false;
          }
        })
        .catch(() => loading.clear());
    },
    //点击下拉商家
    djxiala() {
      var _this = this;
      let data = {
        // uid: 1661,
        uid: this.userInfo.id,
      };
      userModel
        .unamelist(data)
        .then((e) => {
          this.shangjialis = e.data;
          if (e.data.length > 0) {
            if (sessionStorage.getItem("moren") != "true") {
              for (var i in e.data) {
                if (e.data[i].defaultGId == 1) {
                  sessionStorage.setItem("scisok", true);
                  this.shangjia = e.data[i];
                  sessionStorage.setItem("appid", e.data[i].idd);
                  sessionStorage.setItem("gid", e.data[i].id);
                  sessionStorage.setItem("shangjia", JSON.stringify(e.data[i]));
                  console.log(e.data[i]);

                  break;
                } else {
                  sessionStorage.setItem("scisok", true);
                  this.shangjia = e.data[0];
                  sessionStorage.setItem("appid", e.data[0].idd);
                  sessionStorage.setItem("gid", e.data[0].id);
                  sessionStorage.setItem("shangjia", JSON.stringify(e.data[0]));
                }
              }
              sessionStorage.setItem("moren", "true");
            } else {
              sessionStorage.setItem("scisok", true);
              this.shangjia = e.data[0];
              sessionStorage.setItem("appid", e.data[0].idd);
              sessionStorage.setItem("gid", e.data[0].id);
              sessionStorage.setItem("shangjia", JSON.stringify(e.data[0]));
            }
            console.log(this.shangjia);
          } else {
            this.$router.push({
              path: "isok",
            });
            sessionStorage.setItem("scisok", false);
            return;
          }
          if (
            this.shangjia.id == 484 ||
            this.shangjia.id == 208 ||
            this.shangjia.id == 491
          ) {
            this.mengdianisok = false;
            this.carnubercount(); //查看洗车队列当前车辆数量
          } else {
            this.mengdianisok = true;
          }
          this.getisok = true;
          console.log(this.mengdianisok);
          this.tjlist = [];
          this.chain = this.shangjia.chain;
          this.getmodel();
          this.lunbolist();
          this.miaoshalist();
          this.tuijianlist();
          this.splist();

          this.Consumer_partner();
          this.username();
          this.getConfig();
        })
        .catch(() => loading.clear());
    },

    //切换维修厂
    qiehuan(item) {
      this.shangjia = item;
      this.msid = 0;
      this.moren(item);
      this.$refs.item.toggle();
    },
    moren(item) {
      let data = {
        gid: item.id,
        uid: this.userInfo.id,
        // uid: 1661
      };
      userModel
        .changedefault(data)
        .then((e) => {
          sessionStorage.setItem("appid", item.idd);
          sessionStorage.setItem("gid", item.id);
          sessionStorage.setItem("shangjia", JSON.stringify(item));
          this.djxiala();
        })
        .catch(() => loading.clear());
    },
    carnubercount() {
      washcount({ gid: this.shangjia.id }).then((res) => {
        if (res.code == 200) {
          this.carnuber = res.data;
        } else {
          this.$toast(res.data.message);
        }
      });
    },
    username() {
      let data = {
        gid: this.shangjia.id,
        uid: this.userInfo.id,
        // uid: 1661
      };
      orderModel
        .username(data)
        .then((e) => {
          if (e.code == 200) {
            // console.log(e)
            this.users = e.data;
            sessionStorage.setItem("usedCar", e.data.usedCar);
            this.userInfo.phone = e.data.phone;
            this.userInfo.mainName = e.data.mainName;
            this.userInfo.greenVip = e.data.greenVip;
            localStorage.setItem("userInfo", JSON.stringify(this.userInfo));
            // sessionStorage.setItem("chexin", JSON.stringify(e.data.mallUserCarInfoDO));
          }
        })
        .catch(() => loading.clear());
    },
    //去结算
    tojiesuan() {
      this.clist.id = this.clist.oid;
      this.$router.push({
        path: "consume_details",
        query: {
          id: this.clist.id,
          title: "消费记录详情",
        },
      });
      localStorage.setItem("xiaofei", JSON.stringify(this.clist));
      // this.$router.push({
      //   path: "ka_pay",
      //   query: {
      //     isok: false
      //   }
      // });
    },
    getmodel() {
      userModel.carInfomodel().then((e) => {
        console.log(e.data);
        if (e.data == undefined) {
          sessionStorage.setItem("chexin", null);
        } else {
          this.chexin = e.data;
          sessionStorage.setItem("chexin", JSON.stringify(e.data));
          this.jianchache();
        }
        // if(e.data)
      });
    },
    onConfirm(value) {
      console.log(value);
      this.clist = value;
      this.showPicker = false;
    },
    //获取会员等级 是否消费合伙人
    Consumer_partner() {
      const data = {
        gid: this.shangjia.id,
        uid: this.userInfo.id,
      };
      orderModel
        .count(data)
        .then((e) => {
          // console.log("asdadasdasdadasdas");
          sessionStorage.setItem("partnerLevel", e.data.partnerLevel);
          this.tabshow = true;
        })
        .catch(() => loading.clear());
    },
  },
  created() {
    //用来审核微信小程序
    // var isWxMini = window.__wxjs_environment === "miniprogram";
    // if (isWxMini) {
    //   this.$router.push({
    //     path: "invitation",
    //   });
    //   return
    // }


    if (sessionStorage.getItem("gid") == null) {
      this.gid = utils.getUrlKey("gid");
    }
    if (utils.getUrlKey("gid") != null) {
      sessionStorage.setItem("gid", utils.getUrlKey("gid"));

      sessionStorage.setItem("Agid", utils.getUrlKey("gid"));
      if (
        utils.getUrlKey("gid") == 208 ||
        utils.getUrlKey("gid") == 484 ||
        utils.getUrlKey("gid") == 491
      ) {
        this.mengdianisok = false;
      } else {
        this.mengdianisok = true;
      }
      console.log(this.mengdianisok);
    }
    //  console.log(utils.getUrlKey("gid"));
    //  this.onLoad();
    if (JSON.parse(sessionStorage.getItem("shangjia")) == null) {
      //-----------这里 小程序------
      this.onLoad();
      //-----------这里 小程序------
    } else {
      this.shangjia = JSON.parse(sessionStorage.getItem("shangjia"));
      this.chain = this.shangjia.chain;

      if (localStorage.getItem("Authorization")) {
        var isok = localStorage.getItem("isok");
        if (isok == "true") {
          setTimeout((res) => {
            this.$router.go(0);
          }, 100);
          localStorage.removeItem("isok");
        }
        this.djxiala();

        // this.hqcstID();
      }
    }
    // var isokcofig = sessionStorage.getItem("isokcofig");
    // if (isokcofig == "true") {
    //   setTimeout(res => {
    //     this.$router.go(0);
    //   }, 100);
    //   sessionStorage.removeItem("isokcofig");
    // }

    // if (localStorage.getItem("Authorization") != null) {
    //   if (sessionStorage.getItem("quan") == null) {
    //     this.linquan();
    //   }
    // }
    if (
      this.userInfo.mainName == "易车驹门店测试" ||
      localStorage.getItem("Sgid") == "208" ||
      localStorage.getItem("Sgid") == "484"
    ) {
      document.title = "驿车驹" + "(" + "ID:" + this.userInfo.id + ")";
    } else if (localStorage.getItem("Sgid") == "491") {
      console.log(localStorage.getItem("Sgid"));
      document.title = "亿迈程—胜创";
    } else {
      document.title =
        this.userInfo.mainName + "(" + "ID:" + this.userInfo.id + ")";
    }

    console.log(document.title);
  },
  mounted() {
    var _this = this;
  },
  beforeDestroy() {
    if (this.clearTimeSet) {
      //如果定时器还在运行 或者直接关闭，不用判断
      console.log("执行了");
      clearInterval(this.clearTimeSet);
      this.clearTimeSet = null;
    }
    //跳转页面时关闭定时器
    if (this.timers) {
      clearInterval(this.timers);

      // console.log('asdasdsad')
    }
    console.log("执行了");
  },
  destroyed() {
    clearInterval(this.clearTimeSet);
    this.clearTimeSet = null;
  },
  activated() {},
};
</script>
<style lang="less" scoped>
[v-cloak] {
  display: none;
}

// 新的ui*++++++++-------------------
.newbeijin {
  position: relative;
  background: #f1f1f1;
  height: 100%;
}

.newtou {
  position: relative;

  color: #fff;
  // background: #df3d48;
  background: url(../../assets/newimg/tou.png);
  background-size: 100%;
  font-size: 16px;
  font-weight: bold;
}

.newchebei {
  position: absolute;
  top: 0;
  left: 50%;
  width: 336px;
  margin-left: -168px;
  box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.06);
  background: url(../../assets/newimg/chebei.png);
  background-size: 100% 100%;
  border-radius: 4px;
  border-radius: 4px;
  // height: 50px;
  min-height: 55px;
  height: 55px;
  margin-top: 37px;
  font-size: 14px;
  padding: 0px 12px;
  z-index: 5;
}

.new-swipe {
  width: 336px;
  margin: auto;
  margin-top: 65px;
  height: 120px;
  border-radius: 10px;
}

.new-img {
  height: 120px;
  width: 100%;
  border-radius: 10px;
}

.newtou_b {
  margin: auto;
  width: 336px;
  margin-top: 10px;
  overflow: hidden;
}

.tejia {
  width: 68px;
  height: 51px;
  display: block;
  margin: 4px 0 0 4px;
  border-radius: 4px;
}

.tejiatext {
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  color: #0018af;
  line-height: 16px;
}

// .imgzuo {
//   width: 75px;
//   height: 51px;
//   margin-left: 8px;
//   margin-top: 15px;
// }
// .imgzuoy {
//   width: 75px;
//   height: 51px;
//   margin-left: 2px;
//   margin-top: 15px;
// }
.imgzuo2 {
  border-radius: 8px;
  width: 166px;
  height: 73px;
}

.newtou_b_a {
  width: 75px;
  height: 73px;

  background: url(../../assets/newimg/tou_a.png);
  background-size: 100% 100%;
}

.newtou_b_ac {
  width: 75px;
  height: 73px;

  background: url(../../assets/newimg/touzuo.png);
  background-size: 100% 100%;
  border-radius: 6px;
}

.newtou_b_b {
  width: 166px;
  height: 73px;
  margin-right: 10px;
  margin-left: 10px;
  background: url(../../assets/newimg/tou_b.png);
  background-size: 100% 100%;
  position: relative;
  border-radius: 4px;
}

.newshuang {
  height: 25px;
  position: absolute;
  top: 0;
  left: 50%;
  width: 88px;
  margin-left: -44px;
}

.newicos {
  border-radius: 10px 10px 0px 0px;
  background: #fff;
  overflow: hidden;
  // height: 155px;
  padding-bottom: 15px;
  margin-top: 10px;
}

.new-links {
  // padding: 15px 0;
  margin-top: 21px;
  font-size: 12px;
  text-align: center;
}

.newmiaosha {
  width: 340px;
  height: 154px;
  margin: auto;
  background: url(../../assets/newimg/miaosha.png);
  background-size: 100% 100%;
  margin-top: 10px;
}

.newjsxiche {
  width: 340px;
  height: 127px;
  margin: auto;
  background: url(../../assets/newimg/fwbeijin2.png);
  background-size: 100% 100%;
  margin-top: 10px;
}

.newms_a {
  height: 32px;
  line-height: 32px;
  overflow: hidden;
}

.czimg {
  height: 22px;
  margin: 5px 0 0 7px;
}

.czname {
  font-weight: 400;
  color: #ffffff;
  font-size: 12px;
  margin-right: 9px;
}

.newms_lis {
  overflow: hidden;
}

.newms_lis_a {
  margin-left: 10px;
}

// .anim-opacity2 {
//   animation: 5s opacity2 2s infinite;
//   // -webkit-animation: 2s opacity2 0s infinite;
//   // -moz-animation: 2s opacity2 0s infinite;
// }
// @keyframes opacity2 {
//   0% {
//     opacity: 0;
//   }
//   50% {
//     opacity: 0.8;
//   }
//   100% {
//     opacity: 0;
//   }
// }
// @-webkit-keyframes opacity2 {
//   0% {
//     opacity: 0;
//   }
//   50% {
//     opacity: 0.8;
//   }
//   100% {
//     opacity: 0;
//   }
// }
// @-moz-keyframes opacity2 {
//   0% {
//     opacity: 0;
//   }
//   50% {
//     opacity: 0.8;
//   }
//   100% {
//     opacity: 0;
//   }
// }

.new_neirong {
  width: 150px;
  margin: auto;
  margin-top: 4px;
  overflow: hidden;

  .hedimg {
    width: 76px;
    height: 73px;
    display: block;
    margin: auto;
  }
}

.new_jiner {
  text-align: center;
  // line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.new_jiner_a {
  font-size: 14px;
  color: #ff1f21;
  font-weight: bold;

  .suo {
    font-size: 12px;
  }
}

.new_jiner_b {
  font-size: 12px;
  color: #666666;
  font-weight: 400;
  margin-left: 2px;
  display: flex;
  align-items: center;

  img {
    height: 11px;
  }
}

.newms_lis_b {
  margin-right: 10px;
}

.newms_lis_a,
.newms_lis_b {
  width: 155px;
  height: 102px;
  background: #f7f7f7;
  border-radius: 8px;
  margin-top: 8px;
}

.newchaozhikaiye {
  overflow: hidden;
  width: 340px;
  // height: 92px;
  margin: auto;

  margin-top: 10px;
  position: relative;
}

.newchaozhi {
  width: 340px;
  // padding-top:9px;
  height: 112px;
  margin: auto;

  margin-top: 10px;
  position: relative;
}

.newczimg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  margin-top: 9px;
}

.newkaiyeimg {
  display: block;
  width: 100%;
}

.newtext {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;

  margin-left: 16px;
  font-weight: 600;
  text-align: left;
  color: #242424;
}

.newtext_a {
  margin-top: 30px;
  height: 29px;
  line-height: 29px;
  font-size: 16px;
  opacity: 0.85;
}

.newjin {
  color: #ff1f21;
  font-size: 20px;
}

.newczimg2 {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -70px;
  width: 140px;
  height: 29px;
  z-index: 7;
  background: url(../../assets/newimg/czimgtop.png);
  background-size: 100%;
  font-size: 17px;
  color: #fff;
  text-align: center;
  font-family: PangMenZhengDao, PangMenZhengDao-Regular;
  font-weight: 400;
}

.newtext_b {
  width: 100px;
  height: 25px;
  background: url(../../assets/newimg/chaozhi.png);
  background-size: 100%;
  margin-top: 6px;
  font-size: 12px;
  text-align: center;
  line-height: 23px;
  color: #fff;
}

.newxl {
  opacity: 0.85;
  float: left;
  margin-left: 10px;
  // vertical-align: middle;
}

.newicom {
  float: left;
  margin-top: 5px;
}

.newgundong {
  width: 340px;
  height: 33px;
  background: url(../../assets/newimg/toutiao.png);
  background-size: 100%;
  margin: auto;
  margin-top: 10px;
  position: relative;
  overflow: hidden;
}

.newgundong_a {
  font-weight: 600;
  font-size: 13px;
  line-height: 33px;
  margin-left: 34px;
}

.newgundong_b {
  height: 29px;
  line-height: 29px;
  margin-top: 1px;
  width: 72%;
}

.newqtbaoyan {
  width: 340px;
  margin: auto;

  // overflow: hidden;
  font-size: 14px;
}

.van-list {
  overflow: hidden;
}

.qtbaoyan_a {
  margin-top: 10px;
  width: 164px;
  height: 212px;
  background: url(../../assets/newimg/tcbeijin.png);
  background-size: 100%;
  float: left;
  border-radius: 10px;
}

.qtbaoyan_a:nth-child(2n) {
  float: right;
}

.newjptext {
  color: #fff;
  height: 32px;
  line-height: 32px;
  padding: 0 7px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.new_jinimg {
  width: 144px;
  height: 126px;
  display: block;
  margin: auto;
  margin-top: 9px;
}

.new-body {
  padding: 0 8px;
  margin-top: 10px;
}

.newcartan {
  width: 280px;
  // position: relative;
  background-color: rgba(255, 255, 255, 0);
}

.newcaiimg {
  width: 100%;
  display: block;
}

.newcha {
  width: 30px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
}

// *------------------------------------
// *-----------------二维码样式--------
.ewmlis {
  width: 85%;
  border-radius: 8px;
  border: 3px solid #eeb601;
}

.yaosierweim {
  width: 260px;
  padding: 20px 0;
  text-align: center;
  font-size: 16px;
}

.mian {
  background: #fff;
  // height: 350px;
  padding-bottom: 30px;
  border-radius: 8px;
  font-size: 14px;
  color: #666;
}

.shoukuan {
  color: #eeb601;
  padding: 0 15px;
  border-radius: 8px 8px 0 0;
  height: 50px;
  line-height: 50px;
  background: #f7f7f7;
}

.ma {
  padding: 0 15px;
  text-align: center;
}

.daizi {
  width: 20px;
  height: 20px;
  vertical-align: middle;
}

.shou {
  margin-left: 4px;
  vertical-align: middle;
}

.haoyou {
  margin-top: 2px;
  line-height: 40px;
  color: #000;
  font-size: 15px;
}

.jiner {
  margin-top: 2px;
  font-weight: bold;
  color: #000;
  font-size: 28px;
  font-stretch: ultra-condensed;
}

.qy_code_wrapper {
  padding: 0 15px;
  text-align: center;
  margin-top: 15px;
}

.qy_code_wrapper img {
  width: 180px;
  height: 180px;
}

.inptt {
  padding: 15px 0;
  font-size: 26px;
  margin: 0 20px;
  background: #fff;
  border-bottom: 1px solid #f5f5f5;
}

.icpt {
  margin-left: 7px;
  display: inline-block;
  width: 80%;
  border: none;
}

// ---------*-------------------------------
.tichengsw {
  padding: 20px 10px;
  width: 240px;
  font-size: 15px;
  text-align: center;
  // height: 120px;
  border: 1px solid #999;
}

.tcboten {
  width: 100px;
  height: 28px;
  margin-top: 15px;
}

.ticname {
  padding-bottom: 10px;
  line-height: 20px;
}

.tcje {
  font-size: 14px;
}

.vue-waterfall {
  padding: 0 10px;
}

.morens {
  width: 16px;
  vertical-align: middle;
  margin-right: 5px;
}

.item-desc {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  // max-height: 48px;
}

.dian {
  float: right;
  font-weight: bold;
}

.dingwei {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.dingwei2 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9;

  opacity: 0;
}

.cell-item {
  margin-bottom: 10px;
  font-size: 13px;
  background: #fff;
  border-radius: 4px;
}

.item-body {
  padding: 5px 10px 12px 10px;
}

.hua_lis {
  width: 23%;
}

.msdiv {
  padding: 1px 5px 5px 5px;
}

.user-bor {
  width: 94%;
  margin: auto;
  border-radius: 10px;
  padding: 5px 0px;
}

.notice-swipe {
  height: 45px;
  line-height: 48px;
}

.mis_bot {
  width: 90%;
  margin: auto;
}

.van-notice-bar {
  text-align: left;
  font-size: 13px;
}

.mis_bot .mis_img:nth-child(2) {
  float: right;
}

.mis_imgs {
  width: 65px;
  height: 65px;
  float: left;
}

.mis_img {
  width: 100%;
  height: 70px;
  float: left;
}

.gundong {
  position: absolute;
  left: 0;
  top: 0;
  width: 75%;
  margin-left: 90px;
}

.van-swipe-item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.zn_img {
  width: 100%;

  display: block;
}

.tb_img {
  width: 60%;
}

.qushezhi {
  padding: 8px 25px;
}

.zhuantai {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 34px;
  width: 100%;
  background: #fafad2;
  margin-bottom: 50px;
  z-index: 9999;
  overflow: hidden;
}

.cheche {
  width: 24px;
  margin-left: 15px;
  margin-top: 5px;
  float: left;
}

.chep {
  float: left;
  display: flex;

  align-items: center;
  height: 100%;
  // margin-top: 8px;
  margin-left: 3%;
  font-size: 13px;
  // line-height: 34px;
}

.xiaxia {
  float: left;
  color: #666;
  width: 18px;
  margin-top: 8px;
  margin-right: 10px;
}

.xyaoshiimg {
  float: left;
  color: #666;
  width: 18px;
  margin-top: 10px;
  margin-left: 5px;
}

.zhiboimg {
  float: left;
  color: #666;
  width: 14px;
  margin-top: 10px;
  margin-left: 12px;
}

.xiaxiad {
  float: left;
  color: #666;
  width: 16px;
  margin-top: 9px;
}

.gits {
  width: 145px;
  margin-top: 18px;
  // display: block;
  // margin: auto;
}

.moni {
  float: right;
  font-size: 16px;
  display: flex;

  align-items: center;
  height: 34px;
  margin-right: 4%;
  color: red;
}

.shigong {
  float: left;
  display: flex;

  align-items: center;
  height: 100%;
  margin-left: 3%;
  font-size: 13px;
  font-weight: bold;
  color: red;
}

.woai {
  font-family: PingFangSC-Medium;
  font-size: 14px;
  color: #000000;
  letter-spacing: 0;
  line-height: 50px;
  font-weight: bold;
}

.xicarposx {
  position: fixed;
  right: 0;
  bottom: 0;
  margin-bottom: 80px;
  margin-right: 10px;
}

.carnube {
  position: absolute;
  display: block;
  text-align: center;
  color: red;
  font-weight: bold;
  top: 50%;
  left: 50%;
  font-size: 12px;
  line-height: 14px;
  height: 14px;
  width: 14px;
  margin-left: -7px;
  margin-top: -7px;
}

.xicarposx img {
  width: 74px;
  display: block;
  margin-bottom: 5px;
}

.baojia {
  float: right;
  margin-top: 7px;
  margin-right: 12px;
  font-size: 13px;
  // padding: 0 4px;
  width: 52px;
  line-height: 14px;
  padding: 2px 0;
  // height: 20px;
  display: flex;
  justify-content: center;

  align-items: center;
  //  text-align: center;
  border: 1px solid red;
  border-radius: 8px;
  font-weight: bold;
  color: #666;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.user-links::-webkit-scrollbar {
  display: none;
}

.pinming {
  text-align: center;
  line-height: 18px;
  color: #666;
  padding: 0 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.block {
  position: relative;
  width: 100%;
  // height: 120px;
  // background-color: #fff;
  padding: 30px;
}

.guanbi {
  position: absolute;
  right: 0;
  top: 0;
  color: #fff;
}

.qianggpu {
  height: 120px;
  width: 100%;
  display: block;
}

.qianggou:nth-of-type(n + 6) {
  margin-top: 15px;
}

.biaoti {
  position: relative;
  height: 69px;
  color: #fff;
  // background: #df3d48;
  background: url(../../assets/img/hed.png);
  background-size: 100%;
  font-size: 16px;
  font-weight: bold;
}

.lis_sj {
  max-height: 400px;
  position: relative;
  overflow-y: auto;
  padding-bottom: 50px;
}

.sjname {
  text-align: center;
  line-height: 36px;
  height: 36px;
  position: relative;
}

.qqshez {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
}

.logou_che {
  width: 26px;
  height: 26px;
  margin-left: 15px;
  vertical-align: middle;
}

.miao_img {
  border-radius: 4px 4px 0 0;
  width: 100%;
}

.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 120px;
  height: 120px;
  text-align: center;
  border-radius: 10px;
  // background-color: #39a9ed;
}

.user-hes .van-icon {
  display: block;
  font-size: 24px;
}

.zhe {
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #666666;
  line-height: 13px;
  letter-spacing: 0;
  float: right;
}

.cpbj {
  font-weight: 400;
  color: #fff;
  font-family: JDZhengHT-Regular;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  height: 26px;
  width: 80px;
  background: url(../../assets/img/chepaibj.png) no-repeat;
  background-size: 100% 100%;
}

.taocantop {
  width: 110px;
  position: absolute;
  left: 50%;
  top: 0;
  margin-left: -55px;
  height: 40px;
}
</style>
<style lang="less">
.xiaoxi {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 24px;
  margin-right: 10px;
  margin-top: 10px;
}

.ischexin {
  float: left;
}

.sjname .van-dropdown-menu__bar {
  background: rgba(0, 0, 0, 0);
}

.sjname .van-dropdown-menu__title {
  color: #fff;
}

.sjname .van-dropdown-menu__title::after {
  opacity: 1;
  border-color: transparent transparent #fff #fff;
}

.cwode {
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #666666;
  line-height: 14px;
  margin-top: 6px;
  letter-spacing: 0;
}

.cpbeijin {
  height: 25px;
  vertical-align: middle;
}

.bwoche {
  overflow: hidden;
  font-family: PingFangSC-Medium;
  font-size: 14px;
  color: #000000;
  margin-top: 4px;
  letter-spacing: 0;
  line-height: 26px;
  padding-bottom: 8px;
}

.gengai {
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -12px;
  font-size: 13px;
  margin-right: 12px;
  height: 24px;
  width: 24px;
}

.danwei {
  font-size: 13px;
}

.flx {
  display: flex;
}

.jin {
  color: #f6321e;
  // width: 100%;
  font-family: JDZhengHT-Regular;
  font-size: 18px;
  // color: #df3d48;
  font-weight: 500;
  letter-spacing: 0;
  // text-align: right;
}

.del {
  font-family: JDZhengHT-Regular;
  font-size: 12px;
  color: #666;
  letter-spacing: 0;
  margin-left: 2px;
  font-weight: 500;
  display: flex;
  align-items: center;

  img {
    height: 12px;
  }
}

.miaosha {
  // width: 100%;
  // width: 110px;
  height: 45px;
  display: block;
}

.van-ellipsis {
  font-weight: bold;
}

.user-img {
  height: 120px;
  width: 94%;
  border-radius: 10px;
}

.ai {
  font-size: 9px;
  line-height: 10px;
  color: #000;
  padding-left: 25px;
  padding-top: 3px;
}

.ai2 {
  position: absolute;
  top: 0;
  left: 50%;
  width: 94%;
  margin-left: -47%;
  background: #ffffff;
  box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  border-radius: 4px;
  // height: 50px;
  min-height: 50px;
  margin-top: 40px;
  font-size: 14px;
  padding: 0px 12px;
  z-index: 5;
}

.newjiuimg {
  width: 49%;
  border-radius: 4px;
}

.urs_lis {
  margin-top: 2px;
}

.new_lis {
  width: 20%;
  font-size: 12px;
  color: #666666;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  display: inline-block;
  float: none;
  letter-spacing: 0;
  text-align: center;
}

// -----------------------------
.vip {
  position: relative;
}

.newtop {
  margin-top: 20px !important;
}

.newtopB {
  margin-top: 50px !important;
}

.van-dropdown-menu__bar {
  box-shadow: unset !important;
}

.car_no {
  text-align: right;
  position: absolute;
  top: 12%;
  right: 5%;
  color: #fff;
}

.money {
  text-align: center;
  position: absolute;
  top: 45%;
  right: 0;
  color: #fff;
  font-size: 1.2rem;
}

.vip_l {
  text-align: left;
  position: absolute;
  bottom: 10%;
  left: 10%;
  color: #865e24;
}

.vip_r {
  text-align: right;
  position: absolute;
  bottom: 10%;
  right: 10%;
  color: #865e24;
}

.user {
  &-poster {
    width: 100%;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;
  }
}

.sjname .van-dropdown-menu__bar {
  height: 40px !important;
}
</style>
<style >
html,
body {
  height: 100%;
  overflow-y: visible;
  font-family: PingFangSC-Regular;
}
</style>

